import { LoadingOverlay } from "@mantine/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { AuthApi, GeneralApi } from "./apis";
import { Layout } from "./components/layout";
import { LoginLayout } from "./components/layout/loginLayout";
import { LbfInvoiceList } from "./components/lend/lbf-invoice-page";
import { ReceivedLendDetails } from "./components/received-lend/details";
import { IAuth } from "./interfaces/IAuth";
import { AccountBalance } from "./pages/account-balance/list";
import Login from "./pages/auth/login";
import Verify from "./pages/auth/verify";
import { BuyerOffer } from "./pages/buyer-offer/list";
import { Dashboard } from "./pages/dashboard/dashboard";
import { FinanceProgramDetails } from "./pages/finance-program/details";
import { FinanceProgram } from "./pages/finance-program/list";
import { LimitUsageList } from "./pages/limit-usage/list";
import { InvoicePossible } from "./pages/possible";
import { PossibleProgramList } from "./pages/possible/program-list";
import { ReceivedList } from "./pages/received/list";
import { RepaymentList } from "./pages/repayment/list";
import { RequestsFunding } from "./pages/requests-funding/list";
import { ReturnedList } from "./pages/returned/list";
import { SupplierOffer } from "./pages/supplier-offer/list";
import { authMe } from "./store/Auth";
import { initGeneral } from "./store/General";
import { message } from "./utils/Message";

let __WINDOW_LOADED__ = false;

function App() {
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const { accessToken, sessionScope } = useSelector((state: { auth: IAuth }) => state.auth);
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);

  const init = React.useCallback(async () => {
    setLoading(true);
    (async () => {
      try {
        if (accessToken && sessionScope !== "FORGOT" && sessionScope !== "CHANGE_PASSWORD") {
          const res = await GeneralApi.init();
          dispatch(initGeneral(res));
          const auth = await AuthApi.me();
          dispatch(authMe(auth));
        }
        setLoading(false);
      } catch (error: any) {
        message.error(error?.message);
        setLoading(false);
      }
    })();
    setLoading(false);
  }, [sessionScope, accessToken, dispatch]);

  React.useEffect(() => {
    if (!__WINDOW_LOADED__) init();

    __WINDOW_LOADED__ = true;
  }, [init]);

  if (loading) return <LoadingOverlay visible={loading} />;

  return (
    <Routes>
      <>
        <Route element={<LoginLayout />}>
          <Route path="/verify/:id" element={<Verify />} />
        </Route>
        {accessToken && sessionScope !== "FORGOT" && sessionScope !== "CHANGE_PASSWORD" ? (
          <Route element={<Layout />}>
            <Route path="/" element={<Dashboard />} />
            <Route path="/invoice-possible" element={<PossibleProgramList />} />
            <Route path="/invoice-possible/:id/LBF" element={<LbfInvoiceList />} />
            <Route path="/invoice-possible/:id/SCF" element={<InvoicePossible />} />
            <Route path="/requests" element={<RequestsFunding />} />
            <Route path="/received" element={<ReceivedList />} />

            <Route path="/received/:id" element={<ReceivedLendDetails />} />

            <Route path="/repayment" element={<RepaymentList />} />
            <Route path="/returned" element={<ReturnedList />} />
            <Route path="/limit" element={<LimitUsageList />} />

            <Route path="/finance-program" element={<FinanceProgram />} />
            <Route path="/finance-program/:id" element={<FinanceProgramDetails />} />
            {user?.currentBusiness?.type === "SUPPLIER" && <Route path="/buyer-offer" element={<BuyerOffer />} />}
            {user?.currentBusiness?.type === "BUYER" && <Route path="/supplier-offer" element={<SupplierOffer />} />}
            <Route path="/account-balance" element={<AccountBalance />} />

            <Route path="/auth/login" element={<Navigate to="/" />} />
            <Route path="*" element={<div></div>} />
          </Route>
        ) : (
          <Route element={<LoginLayout />}>
            <Route path="/auth/login" element={<Login />} />
            <Route path="*" element={<Navigate to="/auth/login" />} />
          </Route>
        )}
      </>
    </Routes>
  );
}

export default App;
