import { createStyles, Navbar, Text } from "@mantine/core";
import {
  IconBusinessplan,
  IconChartInfographic,
  IconFileAnalytics,
  IconFileSymlink,
  IconLicense,
  IconReceipt,
  IconReceiptRefund,
  IconRepeat,
  IconReport,
  IconWriting,
} from "@tabler/icons";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { IAuth } from "../../interfaces/IAuth";
import { useScreenDetector } from "../screen-detector";
import { CommingSoon } from "./comming-soon";

const items = [
  { title: "Дашбоард", icon: IconChartInfographic, link: "/" },
  { title: "Боломжит нэхэмжлэх", icon: IconLicense, link: "/invoice-possible" },
  { title: "Санхүүжих хүсэлт", icon: IconBusinessplan, link: "/requests" },
  { title: "Авсан санхүүжилт", icon: IconReceipt, link: "/received" },
  { title: "Эргэн төлөлтүүд", icon: IconFileSymlink, link: "/repayment" },
  { title: "Буцаан дуудсан ", icon: IconReceiptRefund, link: "/returned" },
  { title: "Лимит ашиглалт", icon: IconRepeat, link: "/limit" },
  { title: "Санхүүжилт хөтөлбөр", icon: IconFileAnalytics, link: "/finance-program" },
  { title: "Buyer-ийн санал", icon: IconWriting, link: "/buyer-offer" },
  { title: "Supplier-ийн санал", icon: IconWriting, link: "/supplier-offer" },
  { title: "Тооцоо үлдэгдэл", icon: IconReport, link: "/account-balance" },
];

export function Navigation({ opened, setOpened }: { opened: boolean; setOpened: (e: boolean) => void }) {
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const { isMobile } = useScreenDetector();

  return (
    <>
      <Navbar width={{ sm: 130 }} p="md" style={{ overflowY: "auto", zIndex: 10 }}>
        <Navbar.Section grow>
          {items
            .filter((item: any) => (user?.currentBusiness?.type === "BUYER" ? item.link !== "/buyer-offer" : item.link !== "/supplier-offer"))
            .map((item, index) => (
              <NavItem item={item} key={index} setOpened={setOpened} opened={isMobile && opened} />
            ))}
        </Navbar.Section>
      </Navbar>
    </>
  );
}

type INavItem = {
  title: string;
  icon: typeof IconReceipt;
  link: string;
  disabled?: boolean;
};

function NavItem({ item, setOpened, opened = false }: { item: INavItem; setOpened: (e: boolean) => void; opened: boolean }) {
  const { classes, cx } = useStyles({ color: "golomt" });

  return (
    <CommingSoon position="right" visible={item.disabled}>
      <NavLink
        className={cx(classes.item, item.disabled && "disabled", opened && classes.row)}
        to={!item.disabled ? item.link : "#"}
        onClick={() => {
          setOpened(false);
        }}>
        <item.icon className={classes.icon} size={40} />
        <Text size="xs" mt={7} lh="16px" fw={400}>
          {item.title}
        </Text>
      </NavLink>
    </CommingSoon>
  );
}

const useStyles = createStyles((theme, { color }: { color: string }, getRef) => {
  const icon = getRef("icon");

  return {
    item: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      marginBottom: "20px",
      [`&.disabled .${icon}`]: {
        backgroundColor: `${theme.colors[color][0]}!important`,
        color: `${theme.colors[color][6]}!important`,
      },
      [`&:hover .${icon}`]: {
        backgroundColor: theme.colors[color][6],
        color: theme.white,
      },
      [`&.active .${icon}`]: {
        backgroundColor: theme.colors[color][6],
        color: theme.white,
      },
    },
    icon: {
      ref: icon,
      transition: "background-color 150ms ease, transform 100ms ease",
      color: theme.colors[color][6],
      backgroundColor: theme.colors[color][0],
      borderRadius: 4,
      padding: 8,
    },
    row: {
      display: "flex",
      flexDirection: "row",
      gap: 15,
      justifyContent: "start",
      borderRadius: theme.radius.sm,

      [`&:hover`]: {
        background: theme.colors[color][0],
        transform: "revert",
        animation: "ease-in",
      },
    },
  };
});
